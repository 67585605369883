import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ProtectedRoute from './components/ProtectedRoute';
import MainLayout from './layouts/MainLayout';
import ArticleList from './pages/Article';
import ArticleDetail from './pages/Article/Detail';
import ArticleCreate from './pages/Article/Create';
import Banner from './pages/Banner';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import MerchantGallery from './pages/MerchantGallery';
import OrderList from './pages/OrderList';
import OrderListDetail from './pages/OrderList/Detail';
import PageNotFound from './pages/PageNotFound';
import ProductList from './pages/Product/List';
import ProductDetail from './pages/Product/Detail';
import ProductDuplicate from './pages/Product/Duplicate';
import Settings from './pages/Settings';
import ProductCreate from './pages/Product/Create';
import TrackerTransaction from './pages/TrackerReport/Transaction';
import TrackerUserProfile from './pages/TrackerReport/UserProfile';
import TrackerMerchantAndProduct from './pages/TrackerReport/MerchantAndProduct';
import ListOfTicket from './pages/OperationAndFinance/ListOfTicket';
import MerchantSaldo from './pages/OperationAndFinance/MerchantSaldo';
import ArticlePilihan from './pages/Article/Pilihan';
import MerchantList from './pages/Merchant/List';
import MerchantCreate from './pages/Merchant/Create';
import MerchantDetail from './pages/Merchant/Detail';
import UsersList from './pages/Users/List';
import UserDetail from './pages/Users/Detail';
import PictureGallery from './pages/PictureGallery';
import Homepage from './pages/Homepage';
import JournalImmunization from './pages/Journal/Immunization/List';
import ImmunizationAdd from './pages/Journal/Immunization/Add';
import ImmunizationDetail from './pages/Journal/Immunization/Detail';
import ImmunizationEdit from './pages/Journal/Immunization/Edit';
import CodePromoMarketplaceList from './pages/CodePromo/Marketplace/List';
import CodePromoMarkeplaceAdd from './pages/CodePromo/Marketplace/Add';
import CodePromoPremiumList from './pages/CodePromo/Premium/List';
import CodePromoMarkeplaceEdit from './pages/CodePromo/Marketplace/Edit';
import CodePromoPremiumAdd from './pages/CodePromo/Premium/Add';
import CodePromoPremiumEdit from './pages/CodePromo/Premium/Edit';
import KiddofestBannerCreate from './pages/Kiddofest/Banner/Create';
import KiddofestBanner from './pages/Kiddofest/Banner';
import KiddofestBannerEdit from './pages/Kiddofest/Banner/Edit';
import KiddofestGalleryCreate from './pages/Kiddofest/Gallery/Create';
import KiddofestGallery from './pages/Kiddofest/Gallery';
import KiddofestGalleryEdit from './pages/Kiddofest/Gallery/Edit';
import KiddofestTestimoni from './pages/Kiddofest/Testimoni/List';
import KiddofestTestimoniAdd from './pages/Kiddofest/Testimoni/Add';
import KiddofestTestimoniEdit from './pages/Kiddofest/Testimoni/Edit';
import KiddofestEdufairList from './pages/Kiddofest/Edufair/List';
import KiddofestEdufairCreate from './pages/Kiddofest/Edufair/Create';
import KiddofestEdufairEdit from './pages/Kiddofest/Edufair/Edit';
import RegistrationMerchantKiddofest from './pages/Kiddofest/Registration/List';
import KiddofestListSchedule from './pages/Kiddofest/Schedule/List';
import KiddofestScheduleCreate from './pages/Kiddofest/Schedule/Create';
import KiddofestScheduleEdit from './pages/Kiddofest/Schedule/Edit';
import KiddofestCoupon from './pages/Kiddofest/Coupon/List';
import LMSTeamList from './pages/LMS/Team/List';
import LMSTeamCreate from './pages/LMS/Team/Add';
import LMSTeamEdit from './pages/LMS/Team/Edit';
import LMSTestimoni from './pages/LMS/Testimoni/List';
import LMSTestimoniCreate from './pages/LMS/Testimoni/Add';
import LMSTestimoniEdit from './pages/LMS/Testimoni/Edit';
import InboxPage from './pages/Inbox';
import LMSCertificate from './pages/LMS/Certificate/List';
import LMSParticipant from './pages/LMS/Participant/LIst';
import LMSQuiz from './pages/LMS/Quiz/List';
import LMSQuizCreate from './pages/LMS/Quiz/Add';
import LMSClassList from './pages/LMS/Class/List';
import LMSClassCreate from './pages/LMS/Class/Add';
import LMSQuizEdit from './pages/LMS/Quiz/Edit';
import LMSClassEdit from './pages/LMS/Class/Edit';
import MerchantHistorySubscription from './pages/Merchant/History';
import FlashSaleList from './pages/FlashSale/List';
import FlashSaleAdd from './pages/FlashSale/Add';
import FlashSaleEdit from './pages/FlashSale/Edit';
import KioBanner from './pages/Kio/MasterBanner';
import KioBannerCreate from './pages/Kio/MasterBanner/Create';
import KioBannerEdit from './pages/Kio/MasterBanner/Edit';
import KioMasterEpisode from './pages/Kio/MasterEpisode';
import MasterEpisodeCreate from './pages/Kio/MasterEpisode/Create';
import KioSubEpisodeList from './pages/Kio/MasterEpisode/SubEpisode';
import SubEpisodeCreate from './pages/Kio/MasterEpisode/SubEpisode/Create';
import SubEpisodeEdit from './pages/Kio/MasterEpisode/SubEpisode/Edit';
import MasterEpisodeEdit from './pages/Kio/MasterEpisode/Edit';
import CampaignBanner from './pages/Campaign/Banner/list';
import CampaignPage from './pages/Campaign/Page/List';
import CampaignAdd from './pages/Campaign/Page/Add';
import CampaignEdit from './pages/Campaign/Page/Edit';
import CampaignBannerAdd from './pages/Campaign/Banner/add';
import CampaignBannerEdit from './pages/Campaign/Banner/edit';
import GiftCategory from './pages/Gift/Category/List';
import GiftCategoryAdd from './pages/Gift/Category/Add';
import GiftCategoryEdit from './pages/Gift/Category/Edit';
import OrderGift from './pages/OrderGift';
import OrderGiftDetail from './pages/OrderGift/DetailOrder';
import GiftProductList from './pages/Gift/Product/List';
import GiftProductAdd from './pages/Gift/Product/Add';
import GiftProductEdit from './pages/Gift/Product/Edit';
import ProviderPrefixList from './pages/PPOB/ProviderPrefix/List';
import ProviderPrefixAdd from './pages/PPOB/ProviderPrefix/Add';
import OrderPpob from './pages/PPOB/OrderPPOB/List';
import ProviderPrefixEdit from './pages/PPOB/ProviderPrefix/Edit';
import PPOBProductList from './pages/PPOB/Products/List';
import OrderPpobDetail from './pages/PPOB/OrderPPOB/Detail';
import ProductPPOB from './pages/PPOB/Products/Add';
import ProductEditPPOB from './pages/PPOB/Products/Edit';

const App = () => {
    return (
        <>
            <Switch>
                <Route path="/" exact component={Login} />
                <Route path="/dashboard/:path?" exact>
                    <ProtectedRoute>
                        <MainLayout>
                            <Switch>
                                <Route
                                    path="/dashboard"
                                    exact
                                    component={Dashboard}
                                />
                                <Route component={PageNotFound} />
                            </Switch>
                        </MainLayout>
                    </ProtectedRoute>
                </Route>

                <Route path="/order-list/:path?" exact>
                    <ProtectedRoute>
                        <MainLayout>
                            <Switch>
                                <Route
                                    path="/order-list"
                                    exact
                                    component={OrderList}
                                />
                                <Route component={PageNotFound} />
                            </Switch>
                        </MainLayout>
                    </ProtectedRoute>
                </Route>

                <Route path="/operation-and-finance/:path?" exact>
                    <ProtectedRoute>
                        <MainLayout>
                            <Switch>
                                <Route
                                    path="/operation-and-finance/list-of-ticket/:path?"
                                    exact
                                    component={ListOfTicket}
                                />
                                <Route
                                    path="/operation-and-finance/merchant-saldo/:path?"
                                    exact
                                    component={MerchantSaldo}
                                />
                                <Route component={PageNotFound} />
                            </Switch>
                        </MainLayout>
                    </ProtectedRoute>
                </Route>

                <Route path="/order-list/detail/:id" exact>
                    <ProtectedRoute>
                        <MainLayout>
                            <Switch>
                                <Route
                                    path="/order-list/detail/:id"
                                    exact
                                    component={OrderListDetail}
                                />
                                <Route component={PageNotFound} />
                            </Switch>
                        </MainLayout>
                    </ProtectedRoute>
                </Route>

                <Route path="/tracker-report/:path?" exact>
                    <ProtectedRoute>
                        <MainLayout>
                            <Switch>
                                <Route
                                    path="/tracker-report/transaction"
                                    exact
                                    component={TrackerTransaction}
                                />
                                <Route
                                    path="/tracker-report/user-profile"
                                    exact
                                    component={TrackerUserProfile}
                                />
                                <Route
                                    path="/tracker-report/merchant-and-product"
                                    exact
                                    component={TrackerMerchantAndProduct}
                                />
                                <Route component={PageNotFound} />
                            </Switch>
                        </MainLayout>
                    </ProtectedRoute>
                </Route>

                <Route path="/article/:path?" exact>
                    <ProtectedRoute>
                        <MainLayout>
                            <Switch>
                                <Route
                                    path="/article"
                                    exact
                                    component={ArticleList}
                                />
                                <Route
                                    path="/article/create"
                                    exact
                                    component={ArticleCreate}
                                />
                                <Route
                                    path="/article/pilihan"
                                    exact
                                    component={ArticlePilihan}
                                />
                                <Route component={PageNotFound} />
                            </Switch>
                        </MainLayout>
                    </ProtectedRoute>
                </Route>

                <Route path="/article/detail/:id" exact>
                    <ProtectedRoute>
                        <MainLayout>
                            <Switch>
                                <Route
                                    path="/article/detail/:id"
                                    exact
                                    component={ArticleDetail}
                                />
                                <Route component={PageNotFound} />
                            </Switch>
                        </MainLayout>
                    </ProtectedRoute>
                </Route>

                <Route path="/homepage" exact>
                    <ProtectedRoute>
                        <MainLayout>
                            <Switch>
                                <Route
                                    path="/homepage"
                                    exact
                                    component={Homepage}
                                />
                                <Route component={PageNotFound} />
                            </Switch>
                        </MainLayout>
                    </ProtectedRoute>
                </Route>

                <Route path="/picture-gallery" exact>
                    <ProtectedRoute>
                        <MainLayout>
                            <Switch>
                                <Route
                                    path="/picture-gallery"
                                    exact
                                    component={PictureGallery}
                                />
                                <Route component={PageNotFound} />
                            </Switch>
                        </MainLayout>
                    </ProtectedRoute>
                </Route>

                <Route path="/merchant/:path?" exact>
                    <ProtectedRoute>
                        <MainLayout>
                            <Switch>
                                <Route
                                    path="/merchant"
                                    exact
                                    component={MerchantList}
                                />
                                <Route
                                    path="/merchant/create"
                                    exact
                                    component={MerchantCreate}
                                />
                                <Route component={PageNotFound} />
                            </Switch>
                        </MainLayout>
                    </ProtectedRoute>
                </Route>
                <Route path="/merchant/detail/:id" exact>
                    <ProtectedRoute>
                        <MainLayout>
                            <Switch>
                                <Route
                                    path="/merchant/detail/:id"
                                    exact
                                    component={MerchantDetail}
                                />
                                <Route component={PageNotFound} />
                            </Switch>
                        </MainLayout>
                    </ProtectedRoute>
                </Route>
                <Route path="/merchant/history/:id" exact>
                    <ProtectedRoute>
                        <MainLayout>
                            <Switch>
                                <Route
                                    path="/merchant/history/:id"
                                    exact
                                    component={MerchantHistorySubscription}
                                />
                                <Route component={PageNotFound} />
                            </Switch>
                        </MainLayout>
                    </ProtectedRoute>
                </Route>

                <Route path="/users/:path?" exact>
                    <ProtectedRoute>
                        <MainLayout>
                            <Switch>
                                <Route
                                    path="/users"
                                    exact
                                    component={UsersList}
                                />
                                <Route component={PageNotFound} />
                            </Switch>
                        </MainLayout>
                    </ProtectedRoute>
                </Route>

                <Route path="/users/detail/:path?" exact>
                    <ProtectedRoute>
                        <MainLayout>
                            <Switch>
                                <Route
                                    path="/users/detail/:id"
                                    exact
                                    component={UserDetail}
                                />
                                <Route component={PageNotFound} />
                            </Switch>
                        </MainLayout>
                    </ProtectedRoute>
                </Route>

                <Route path="/merchant-gallery/:path?" exact>
                    <ProtectedRoute>
                        <MainLayout>
                            <Switch>
                                <Route
                                    path="/merchant-gallery/:path?"
                                    exact
                                    component={MerchantGallery}
                                />
                            </Switch>
                        </MainLayout>
                    </ProtectedRoute>
                </Route>

                <Route path="/banner/:path?" exact>
                    <ProtectedRoute>
                        <MainLayout>
                            <Switch>
                                <Route
                                    path="/banner"
                                    exact
                                    component={Banner}
                                />
                                <Route component={PageNotFound} />
                            </Switch>
                        </MainLayout>
                    </ProtectedRoute>
                </Route>

                <Route path="/product/:path?" exact>
                    <ProtectedRoute>
                        <MainLayout>
                            <Switch>
                                <Route
                                    path="/product"
                                    exact
                                    component={ProductList}
                                />
                                <Route
                                    path="/product/create"
                                    exact
                                    component={ProductCreate}
                                />
                                <Route component={PageNotFound} />
                            </Switch>
                        </MainLayout>
                    </ProtectedRoute>
                </Route>

                <Route path="/product/detail/:id" exact>
                    <ProtectedRoute>
                        <MainLayout>
                            <Switch>
                                <Route
                                    path="/product/detail/:id"
                                    exact
                                    component={ProductDetail}
                                />
                                <Route component={PageNotFound} />
                            </Switch>
                        </MainLayout>
                    </ProtectedRoute>
                </Route>

                <Route path="/product/duplicate/:id" exact>
                    <ProtectedRoute>
                        <MainLayout>
                            <Switch>
                                <Route
                                    path="/product/duplicate/:id"
                                    exact
                                    component={ProductDuplicate}
                                />
                                <Route component={PageNotFound} />
                            </Switch>
                        </MainLayout>
                    </ProtectedRoute>
                </Route>

                <Route path="/settings/:path?" exact>
                    <ProtectedRoute>
                        <MainLayout>
                            <Switch>
                                <Route
                                    path="/settings/:path?"
                                    exact
                                    component={Settings}
                                />
                                <Route component={PageNotFound} />
                            </Switch>
                        </MainLayout>
                    </ProtectedRoute>
                </Route>

                <Route path="/journal/immunization/:path?" exact>
                    <ProtectedRoute>
                        <MainLayout>
                            <Switch>
                                <Route
                                    path="/journal/immunization"
                                    exact
                                    component={JournalImmunization}
                                />
                                <Route
                                    path="/journal/immunization/create"
                                    exact
                                    component={ImmunizationAdd}
                                />
                                <Route
                                    path="/journal/immunization/:id"
                                    component={ImmunizationEdit}
                                />
                                <Route component={PageNotFound} />
                            </Switch>
                        </MainLayout>
                    </ProtectedRoute>
                </Route>

                <Route path="/journal/immunization/detail/:id" exact>
                    <ProtectedRoute>
                        <MainLayout>
                            <Switch>
                                <Route
                                    path="/journal/immunization/detail/:id"
                                    component={ImmunizationDetail}
                                />
                                <Route component={PageNotFound} />
                            </Switch>
                        </MainLayout>
                    </ProtectedRoute>
                </Route>

                <Route path="/codepromo/marketplace/:path?" exact>
                    <ProtectedRoute>
                        <MainLayout>
                            <Switch>
                                <Route
                                    path="/codepromo/marketplace/list"
                                    component={CodePromoMarketplaceList}
                                />
                                <Route
                                    path="/codepromo/marketplace/add"
                                    component={CodePromoMarkeplaceAdd}
                                />
                                <Route
                                    path="/codepromo/marketplace/:id"
                                    component={CodePromoMarkeplaceEdit}
                                />
                                <Route component={PageNotFound} />
                            </Switch>
                        </MainLayout>
                    </ProtectedRoute>
                </Route>

                <Route path="/codepromo/premium/:path?" exact>
                    <ProtectedRoute>
                        <MainLayout>
                            <Switch>
                                <Route
                                    path="/codepromo/premium/list"
                                    component={CodePromoPremiumList}
                                />
                                <Route
                                    path="/codepromo/premium/add"
                                    component={CodePromoPremiumAdd}
                                />
                                <Route
                                    path="/codepromo/premium/:id"
                                    component={CodePromoPremiumEdit}
                                />
                                <Route component={PageNotFound} />
                            </Switch>
                        </MainLayout>
                    </ProtectedRoute>
                </Route>

                <Route path="/kiddofest/:path?" exact>
                    <ProtectedRoute>
                        <MainLayout>
                            <Switch>
                                <Route
                                    path="/kiddofest/banner"
                                    component={KiddofestBanner}
                                />
                                <Route
                                    path="/kiddofest/gallery"
                                    component={KiddofestGallery}
                                />
                                <Route
                                    path="/kiddofest/testimoni"
                                    component={KiddofestTestimoni}
                                />
                                <Route
                                    path="/kiddofest/edufair"
                                    component={KiddofestEdufairList}
                                />
                                <Route
                                    path="/kiddofest/registration"
                                    component={RegistrationMerchantKiddofest}
                                />
                                <Route
                                    path="/kiddofest/schedule"
                                    component={KiddofestListSchedule}
                                />
                                <Route
                                    path="/kiddofest/coupon"
                                    component={KiddofestCoupon}
                                />
                                <Route component={PageNotFound} />
                            </Switch>
                        </MainLayout>
                    </ProtectedRoute>
                </Route>

                <Route path="/kiddofest/banner/:path?" exact>
                    <ProtectedRoute>
                        <MainLayout>
                            <Switch>
                                <Route
                                    path="/kiddofest/banner/add"
                                    component={KiddofestBannerCreate}
                                />
                                <Route
                                    path="/kiddofest/banner/:id"
                                    component={KiddofestBannerEdit}
                                />
                                <Route component={PageNotFound} />
                            </Switch>
                        </MainLayout>
                    </ProtectedRoute>
                </Route>

                <Route path="/kiddofest/gallery/:path?" exact>
                    <ProtectedRoute>
                        <MainLayout>
                            <Switch>
                                <Route
                                    path="/kiddofest/gallery/add"
                                    component={KiddofestGalleryCreate}
                                />
                                <Route
                                    path="/kiddofest/gallery/:id"
                                    component={KiddofestGalleryEdit}
                                />
                                <Route component={PageNotFound} />
                            </Switch>
                        </MainLayout>
                    </ProtectedRoute>
                </Route>

                <Route path="/kiddofest/testimoni/:path?" exact>
                    <ProtectedRoute>
                        <MainLayout>
                            <Switch>
                                <Route
                                    path="/kiddofest/testimoni/add"
                                    component={KiddofestTestimoniAdd}
                                />
                                <Route
                                    path="/kiddofest/testimoni/:id"
                                    component={KiddofestTestimoniEdit}
                                />
                                <Route component={PageNotFound} />
                            </Switch>
                        </MainLayout>
                    </ProtectedRoute>
                </Route>

                <Route path="/kiddofest/edufair/:path?" exact>
                    <ProtectedRoute>
                        <MainLayout>
                            <Switch>
                                <Route
                                    path="/kiddofest/edufair/add"
                                    component={KiddofestEdufairCreate}
                                />
                                <Route
                                    path="/kiddofest/edufair/:id"
                                    component={KiddofestEdufairEdit}
                                />
                                <Route component={PageNotFound} />
                            </Switch>
                        </MainLayout>
                    </ProtectedRoute>
                </Route>

                <Route path="/kiddofest/schedule/:path?" exact>
                    <ProtectedRoute>
                        <MainLayout>
                            <Switch>
                                <Route
                                    path="/kiddofest/schedule/add"
                                    component={KiddofestScheduleCreate}
                                />
                                <Route
                                    path="/kiddofest/schedule/:id"
                                    component={KiddofestScheduleEdit}
                                />
                                <Route component={PageNotFound} />
                            </Switch>
                        </MainLayout>
                    </ProtectedRoute>
                </Route>

                <Route path="/learnerscamp/:path?" exact>
                    <ProtectedRoute>
                        <MainLayout>
                            <Switch>
                                <Route
                                    path="/learnerscamp/team"
                                    component={LMSTeamList}
                                />
                                <Route
                                    path="/learnerscamp/testimoni"
                                    component={() => <LMSTestimoni />}
                                />
                                <Route
                                    path="/learnerscamp/certificate"
                                    component={LMSCertificate}
                                />
                                <Route
                                    path="/learnerscamp/participant"
                                    component={LMSParticipant}
                                />
                                <Route
                                    path="/learnerscamp/quiz"
                                    component={LMSQuiz}
                                />
                                <Route
                                    path="/learnerscamp/class"
                                    component={LMSClassList}
                                />

                                <Route component={PageNotFound} />
                            </Switch>
                        </MainLayout>
                    </ProtectedRoute>
                </Route>

                <Route path="/learnerscamp/team/:path?" exact>
                    <ProtectedRoute>
                        <MainLayout>
                            <Switch>
                                <Route
                                    path="/learnerscamp/team/create"
                                    component={LMSTeamCreate}
                                />
                                <Route
                                    path="/learnerscamp/team/:id"
                                    component={LMSTeamEdit}
                                />
                                <Route component={PageNotFound} />
                            </Switch>
                        </MainLayout>
                    </ProtectedRoute>
                </Route>

                <Route path="/learnerscamp/testimoni/:path?" exact>
                    <ProtectedRoute>
                        <MainLayout>
                            <Switch>
                                <Route
                                    path="/learnerscamp/testimoni/create"
                                    component={LMSTestimoniCreate}
                                />
                                <Route
                                    path="/learnerscamp/testimoni/:id"
                                    component={LMSTestimoniEdit}
                                />
                                <Route component={PageNotFound} />
                            </Switch>
                        </MainLayout>
                    </ProtectedRoute>
                </Route>

                <Route path="/learnerscamp/quiz/:path?" exact>
                    <ProtectedRoute>
                        <MainLayout>
                            <Switch>
                                <Route
                                    path="/learnerscamp/quiz/create"
                                    component={LMSQuizCreate}
                                />
                                <Route
                                    path="/learnerscamp/quiz/:id"
                                    component={LMSQuizEdit}
                                />
                                <Route component={PageNotFound} />
                            </Switch>
                        </MainLayout>
                    </ProtectedRoute>
                </Route>

                <Route path="/learnerscamp/class/:path?" exact>
                    <ProtectedRoute>
                        <MainLayout>
                            <Switch>
                                <Route
                                    path="/learnerscamp/class/create"
                                    component={LMSClassCreate}
                                />
                                <Route
                                    path="/learnerscamp/class/:id"
                                    component={LMSClassEdit}
                                />
                                <Route component={PageNotFound} />
                            </Switch>
                        </MainLayout>
                    </ProtectedRoute>
                </Route>

                <Route path="/picture-gallery" exact>
                    <ProtectedRoute>
                        <MainLayout>
                            <Switch>
                                <Route
                                    path="/picture-gallery"
                                    exact
                                    component={PictureGallery}
                                />
                                <Route component={PageNotFound} />
                            </Switch>
                        </MainLayout>
                    </ProtectedRoute>
                </Route>

                <Route path="/inbox" exact>
                    <ProtectedRoute>
                        <MainLayout>
                            <Switch>
                                <Route path="/inbox" component={InboxPage} />
                                <Route component={PageNotFound} />
                            </Switch>
                        </MainLayout>
                    </ProtectedRoute>
                </Route>

                <Route path="/flashsale" exact>
                    <ProtectedRoute>
                        <MainLayout>
                            <Switch>
                                <Route
                                    path="/flashsale"
                                    component={FlashSaleList}
                                />
                                <Route component={PageNotFound} />
                            </Switch>
                        </MainLayout>
                    </ProtectedRoute>
                </Route>

                <Route path="/flashsale/add" exact>
                    <ProtectedRoute>
                        <MainLayout>
                            <Switch>
                                <Route
                                    path="/flashsale/add"
                                    component={FlashSaleAdd}
                                />
                                <Route component={PageNotFound} />
                            </Switch>
                        </MainLayout>
                    </ProtectedRoute>
                </Route>

                <Route path="/flashsale/edit/:path?" exact>
                    <ProtectedRoute>
                        <MainLayout>
                            <Switch>
                                <Route
                                    path="/flashsale/edit/:id"
                                    component={FlashSaleEdit}
                                />
                                <Route component={PageNotFound} />
                            </Switch>
                        </MainLayout>
                    </ProtectedRoute>
                </Route>

                <Route path="/kio/banner" exact>
                    <ProtectedRoute>
                        <MainLayout>
                            <Switch>
                                <Route
                                    path="/kio/banner"
                                    component={KioBanner}
                                />
                                <Route component={PageNotFound} />
                            </Switch>
                        </MainLayout>
                    </ProtectedRoute>
                </Route>

                <Route path="/kio/banner/add" exact>
                    <ProtectedRoute>
                        <MainLayout>
                            <Switch>
                                <Route
                                    path="/kio/banner/add"
                                    component={KioBannerCreate}
                                />
                                <Route component={PageNotFound} />
                            </Switch>
                        </MainLayout>
                    </ProtectedRoute>
                </Route>

                <Route path="/kio/banner/edit/:path?" exact>
                    <ProtectedRoute>
                        <MainLayout>
                            <Switch>
                                <Route
                                    path="/kio/banner/edit/:id"
                                    component={KioBannerEdit}
                                />
                                <Route component={PageNotFound} />
                            </Switch>
                        </MainLayout>
                    </ProtectedRoute>
                </Route>

                <Route path="/kio/episode" exact>
                    <ProtectedRoute>
                        <MainLayout>
                            <Switch>
                                <Route
                                    path="/kio/episode"
                                    component={KioMasterEpisode}
                                />
                                <Route component={PageNotFound} />
                            </Switch>
                        </MainLayout>
                    </ProtectedRoute>
                </Route>

                <Route path="/kio/episode/add" exact>
                    <ProtectedRoute>
                        <MainLayout>
                            <Switch>
                                <Route
                                    path="/kio/episode/add"
                                    component={MasterEpisodeCreate}
                                />
                                <Route component={PageNotFound} />
                            </Switch>
                        </MainLayout>
                    </ProtectedRoute>
                </Route>

                <Route path="/kio/episode/edit/:path?" exact>
                    <ProtectedRoute>
                        <MainLayout>
                            <Switch>
                                <Route
                                    path="/kio/episode/edit/:id"
                                    component={MasterEpisodeEdit}
                                />
                                <Route component={PageNotFound} />
                            </Switch>
                        </MainLayout>
                    </ProtectedRoute>
                </Route>

                <Route path="/kio/sub/episode/:path?" exact>
                    <ProtectedRoute>
                        <MainLayout>
                            <Switch>
                                <Route
                                    path="/kio/sub/episode/:id"
                                    component={KioSubEpisodeList}
                                />
                                <Route component={PageNotFound} />
                            </Switch>
                        </MainLayout>
                    </ProtectedRoute>
                </Route>

                <Route path="/kio/sub/episode/create/:path?" exact>
                    <ProtectedRoute>
                        <MainLayout>
                            <Switch>
                                <Route
                                    path="/kio/sub/episode/create/:id"
                                    component={SubEpisodeCreate}
                                />
                                <Route component={PageNotFound} />
                            </Switch>
                        </MainLayout>
                    </ProtectedRoute>
                </Route>

                <Route path="/kio/sub/episode/edit/:path/:path" exact>
                    <ProtectedRoute>
                        <MainLayout>
                            <Switch>
                                <Route
                                    path="/kio/sub/episode/edit/:id/:sub"
                                    component={SubEpisodeEdit}
                                />
                                <Route component={PageNotFound} />
                            </Switch>
                        </MainLayout>
                    </ProtectedRoute>
                </Route>

                <Route path="/campaign/banner" exact>
                    <ProtectedRoute>
                        <MainLayout>
                            <Switch>
                                <Route
                                    path="/campaign/banner"
                                    component={CampaignBanner}
                                />
                                <Route component={PageNotFound} />
                            </Switch>
                        </MainLayout>
                    </ProtectedRoute>
                </Route>

                <Route path="/campaign/banner/add" exact>
                    <ProtectedRoute>
                        <MainLayout>
                            <Switch>
                                <Route
                                    path="/campaign/banner/add"
                                    component={CampaignBannerAdd}
                                />
                                <Route component={PageNotFound} />
                            </Switch>
                        </MainLayout>
                    </ProtectedRoute>
                </Route>

                <Route path="/campaign/banner/edit/:path?" exact>
                    <ProtectedRoute>
                        <MainLayout>
                            <Switch>
                                <Route
                                    path="/campaign/banner/edit/:id"
                                    component={CampaignBannerEdit}
                                />
                                <Route component={PageNotFound} />
                            </Switch>
                        </MainLayout>
                    </ProtectedRoute>
                </Route>

                <Route path="/campaign/page" exact>
                    <ProtectedRoute>
                        <MainLayout>
                            <Switch>
                                <Route
                                    path="/campaign/page/"
                                    component={CampaignPage}
                                />

                                <Route component={PageNotFound} />
                            </Switch>
                        </MainLayout>
                    </ProtectedRoute>
                </Route>

                <Route path="/campaign/page/add" exact>
                    <ProtectedRoute>
                        <MainLayout>
                            <Switch>
                                <Route
                                    path="/campaign/page/add"
                                    component={CampaignAdd}
                                />
                                <Route component={PageNotFound} />
                            </Switch>
                        </MainLayout>
                    </ProtectedRoute>
                </Route>

                <Route path="/campaign/page/edit/:path?" exact>
                    <ProtectedRoute>
                        <MainLayout>
                            <Switch>
                                <Route
                                    path="/campaign/page/edit/:id"
                                    component={CampaignEdit}
                                />
                                <Route component={PageNotFound} />
                            </Switch>
                        </MainLayout>
                    </ProtectedRoute>
                </Route>

                <Route path="/gift/category/:path?" exact>
                    <ProtectedRoute>
                        <MainLayout>
                            <Switch>
                                <Route
                                    path="/gift/category"
                                    exact
                                    component={GiftCategory}
                                />
                                <Route
                                    path="/gift/category/add"
                                    exact
                                    component={GiftCategoryAdd}
                                />
                                <Route
                                    path="/gift/category/:id"
                                    exact
                                    component={GiftCategoryEdit}
                                />
                                <Route component={PageNotFound} />
                            </Switch>
                        </MainLayout>
                    </ProtectedRoute>
                </Route>

                <Route path="/gift/product/:path?" exact>
                    <ProtectedRoute>
                        <MainLayout>
                            <Switch>
                                <Route
                                    path="/gift/product"
                                    exact
                                    component={GiftProductList}
                                />
                                <Route
                                    path="/gift/product/add"
                                    exact
                                    component={GiftProductAdd}
                                />
                                <Route
                                    path="/gift/product/:id"
                                    exact
                                    component={GiftProductEdit}
                                />
                                <Route component={PageNotFound} />
                            </Switch>
                        </MainLayout>
                    </ProtectedRoute>
                </Route>

                {/* <Route path="/gift/product/edit/:id" exact>
                <ProtectedRoute>
                    <MainLayout>
                        <Switch>
                            <Route
                                path="/gift/product/edit/:id"
                                exact
                                component={GiftProductEdit}
                            />
                            <Route component={PageNotFound} />
                        </Switch>
                    </MainLayout>
                </ProtectedRoute>
            </Route> */}

                <Route path="/order-gift/:path?" exact>
                    <ProtectedRoute>
                        <MainLayout>
                            <Switch>
                                <Route
                                    path="/order-gift"
                                    component={OrderGift}
                                />
                                <Route component={PageNotFound} />
                            </Switch>
                        </MainLayout>
                    </ProtectedRoute>
                </Route>

                <Route path="/order-gift/detail/:id" exact>
                    <ProtectedRoute>
                        <MainLayout>
                            <Switch>
                                <Route
                                    path="/order-gift/detail/:id"
                                    exact
                                    component={OrderGiftDetail}
                                />
                                <Route component={PageNotFound} />
                            </Switch>
                        </MainLayout>
                    </ProtectedRoute>
                </Route>

                <Route path="/ppob/provider/:path?" exact>
                    <ProtectedRoute>
                        <MainLayout>
                            <Switch>
                                <Route
                                    path="/ppob/provider"
                                    exact
                                    component={ProviderPrefixList}
                                />
                                <Route
                                    path="/ppob/provider/add"
                                    exact
                                    component={ProviderPrefixAdd}
                                />
                                <Route
                                    path="/ppob/provider/:id"
                                    exact
                                    component={ProviderPrefixEdit}
                                />
                                <Route component={PageNotFound} />
                            </Switch>
                        </MainLayout>
                    </ProtectedRoute>
                </Route>

                <Route path="/ppob/order/:path?" exact>
                    <ProtectedRoute>
                        <MainLayout>
                            <Switch>
                                <Route
                                    path="/ppob/order"
                                    exact
                                    component={OrderPpob}
                                />
                                <Route
                                    path="/ppob/order/:id"
                                    exact
                                    component={OrderPpobDetail}
                                />

                                <Route component={PageNotFound} />
                            </Switch>
                        </MainLayout>
                    </ProtectedRoute>
                </Route>

                <Route path="/ppob/product/:path?" exact>
                    <ProtectedRoute>
                        <MainLayout>
                            <Switch>
                                <Route
                                    path="/ppob/product"
                                    exact
                                    component={PPOBProductList}
                                />
                                <Route
                                    path="/ppob/product/add"
                                    exact
                                    component={ProductPPOB}
                                />
                                <Route
                                    path="/ppob/product/:id"
                                    exact
                                    component={ProductEditPPOB}
                                />
                                <Route component={PageNotFound} />
                            </Switch>
                        </MainLayout>
                    </ProtectedRoute>
                </Route>
            </Switch>
        </>
    );
};

export default App;
